<script>
import { Line } from '../../../node_modules/vue-chartjs'
import EmailGraphService from '@/services/resources/EmailGraphService';
import moment from 'moment'
const serviceEmailGraph =  EmailGraphService.build();

export default {
    data(){
        return{
            isSettedSimple: false,
            isSetted: false,
            interval: null,
            simpleInterval: null,
            data_enviado: [70, 59, 30, 50, 50, 120, 70],
            data_aberto: [60, 49, 20, 50, 50, 110, 50],
            data_clicado: [40, 39, 10, 40, 39, 80, 40],
            data_spam: [70, 59, 30, 50, 50, 120, 70],
            data_deferred: [60, 49, 20, 50, 50, 110, 50],
            data_dropped: [40, 39, 10, 40, 39, 80, 40],
            data_bounce: [70, 59, 30, 50, 50, 120, 70],
            data_sairam: [60, 49, 20, 50, 50, 110, 50],
            labels_actual: [],
            type_graph: 'simples',
            data_padrao: [moment().startOf('hour').subtract(169, 'hour').format('YYYY-MM-DD'), moment().startOf('hour').subtract(1, 'hour').format('YYYY-MM-DD')],

        }
    },
    extends: Line,
    methods:{
        getData(){
            var semana = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
            var inicial = [];
            var meio = [];
            var final = [];
            var d = new Date();
            for (let i = 0; i < semana.length; i++) {
                if(d.getDay() === i){
                    var atual = semana[i];
                    final.push(semana[i]);
                }else if(d.getDay() <= i){
                    var proximos = semana[i];
                    meio.push(semana[i]);
                }else{
                    var ultimos = semana[i];
                    inicial.push(semana[i]);
                }
            }
            var labels_actual = inicial.concat(final);
            var labels_actual = meio.concat(labels_actual);
            this.labels_actual = labels_actual;
        },
        buscaDados(data){
            // console.log(data);
            let data1 = moment(data[0]);
            let data2 = moment(data[1]);
            // var config = {
            //     headers: {'Authorization': 'Ni4xNTczOC41YjA5ZDA1ZjllYjk5YWE4OTQzOGExZDBjMmNkYjQxNmVhNjgyYjQ4MTNlNzM0ODM5NmRlNmQwYWIxNGI1Njhl'}
            // };
            // axios.get('http://localhost:8000/api//email/graph?from_date='+data[0]+'&to_date='+data[1], config)
            serviceEmailGraph
            .search('?from_date='+data[0]+'&to_date='+data[1])
            .then(resp => {
                // console.log(resp);
                this.$root.$emit('getdataupdate', resp);
                this.$root.$emit('getmingraph', data);
                  var enumerateDaysBetweenDates = function(startDate, endDate) {
                    var now = startDate, dates = [];

                    while (now.isSameOrBefore(endDate)) {
                            dates.push(now.format('YYYY-MM-DD'));
                            now.add(1, 'days');
                        }
                    return dates;
                };
                var results = enumerateDaysBetweenDates(data1, data2);
                this.labels_actual = results;

                var delivereds = [];
                for (let i = 0; i < results.length; i++) {
                    const element = results[i];
                    if(resp.data.email_delivereds[results[i]] === undefined){
                        delivereds.push(0);
                    }else{
                        delivereds.push(resp.data.email_delivereds[results[i]])
                    }
                }
                this.data_enviado = delivereds;

                var clicks = [];
                for (let i = 0; i < results.length; i++) {
                    const element = results[i];
                    if(resp.data.email_clicks[results[i]] === undefined){
                        clicks.push(0);
                    }else{
                        clicks.push(resp.data.email_clicks[results[i]])
                    }
                }
                this.data_clicado = clicks;

                var opens = [];
                for (let i = 0; i < results.length; i++) {
                    const element = results[i];
                    if(resp.data.email_opens[results[i]] === undefined){
                        opens.push(0);
                    }else{
                        opens.push(resp.data.email_opens[results[i]])
                    }
                }
                this.data_aberto = opens;

                var unsubscribeds = [];
                for (let i = 0; i < results.length; i++) {
                    const element = results[i];
                    if(resp.data.email_unsubscribeds[results[i]] === undefined){
                        unsubscribeds.push(0);
                    }else{
                        unsubscribeds.push(resp.data.email_unsubscribeds[results[i]])
                    }
                }
                this.data_sairam = unsubscribeds;

                var spams = [];
                for (let i = 0; i < results.length; i++) {
                    const element = results[i];
                    if(resp.data.email_spams[results[i]] === undefined){
                        spams.push(0);
                    }else{
                        spams.push(resp.data.email_spams[results[i]])
                    }
                }
                this.data_spam = spams;

                var bounces = [];
                for (let i = 0; i < results.length; i++) {
                    const element = results[i];
                    if(resp.data.email_bounces[results[i]] === undefined){
                        bounces.push(0);
                    }else{
                        bounces.push(resp.data.email_bounces[results[i]])
                    }
                }
                this.data_bounce = bounces;

                var droppeds = [];
                for (let i = 0; i < results.length; i++) {
                    const element = results[i];
                    if(resp.data.email_droppeds[results[i]] === undefined){
                        droppeds.push(0);
                    }else{
                        droppeds.push(resp.data.email_droppeds[results[i]])
                        }
                }
                this.data_dropped = droppeds;

                var deferreds = [];
                for (let i = 0; i < results.length; i++) {
                    const element = results[i];
                    if(resp.data.email_deferreds[results[i]] === undefined){
                        deferreds.push(0);
                    }else{
                        deferreds.push(resp.data.email_deferreds[results[i]])
                    }
                }
                this.data_deferred = deferreds;

                this.montaGrafico();

            }).catch(err => {
                // console.log(err);
            });
        },
        buscaDadosSimples(data){
            // console.log(data);
            let data1 = moment(data[0]);
            let data2 = moment(data[1]);
            // var config = {
            //     headers: {'Authorization': 'Ni4xNTczOC41YjA5ZDA1ZjllYjk5YWE4OTQzOGExZDBjMmNkYjQxNmVhNjgyYjQ4MTNlNzM0ODM5NmRlNmQwYWIxNGI1Njhl'}
            // };
            // axios.get('http://localhost:8000/api//email/graph?from_date='+data[0]+'&to_date='+data[1], config)
            serviceEmailGraph
            .read('?from_date='+data[0]+'&to_date='+data[1])
            .then(resp => {
                // console.log(resp);
                this.$root.$emit('getdataupdate', resp);
                this.$root.$emit('getmingraph', data);
                  var enumerateDaysBetweenDates = function(startDate, endDate) {
                    var now = startDate, dates = [];
                    
                    while (now.isSameOrBefore(endDate)) {
                            dates.push(now.format('YYYY-MM-DD'));
                            now.add(1, 'days');
                        }
                    return dates;
                };
                var results = enumerateDaysBetweenDates(data1, data2);
                this.labels_actual = results;

                var delivereds = [];
                for (let i = 0; i < results.length; i++) {
                    const element = results[i];
                    if(resp.data.email_delivereds[results[i]] === undefined){
                        delivereds.push(0);
                    }else{
                        delivereds.push(resp.data.email_delivereds[results[i]])
                    }
                }
                this.data_enviado = delivereds;

                var clicks = [];
                for (let i = 0; i < results.length; i++) {
                    const element = results[i];
                    if(resp.data.email_clicks[results[i]] === undefined){
                        clicks.push(0);
                    }else{
                        clicks.push(resp.data.email_clicks[results[i]])
                    }
                }
                this.data_clicado = clicks;

                var opens = [];
                for (let i = 0; i < results.length; i++) {
                    const element = results[i];
                    if(resp.data.email_opens[results[i]] === undefined){
                        opens.push(0);
                    }else{
                        opens.push(resp.data.email_opens[results[i]])
                    }
                }
                this.data_aberto = opens;

                var unsubscribeds = [];
                for (let i = 0; i < results.length; i++) {
                    const element = results[i];
                    if(resp.data.email_unsubscribeds[results[i]] === undefined){
                        unsubscribeds.push(0);
                    }else{
                        unsubscribeds.push(resp.data.email_unsubscribeds[results[i]])
                    }
                }
                this.data_sairam = unsubscribeds;

                this.montaGraficoSimples();

            }).catch(err => {
                // console.log(err);
            });
        },
        montaGrafico(){
            var labelFormatArray = [];
            var labelFormat = this.labels_actual
            for (let i = 0; i < labelFormat.length; i++) {
                const element = moment(labelFormat[i]).format('DD/MM/YYYY');
                labelFormatArray.push(element);
            }
            this.labels_actual = labelFormatArray;
            // console.log("labels", this.labels_actual);
            // console.log("entregues", this.data_enviado);
            // console.log("abertos", this.data_aberto);
            // console.log("clicados", this.data_clicado);
            // console.log("sairam", this.data_sairam);
            // console.log("spam", this.data_spam);
            // console.log("dropped", this.data_dropped);
            // console.log("deferred", this.data_deferred);
            // console.log("bounces", this.data_bounce);
            Chart.defaults.global.defaultFontColor = '#8C8A97';
            Chart.defaults.global.defaultFontFamily = 'Montserrat';
            // Chart.defaults.global.legend.align = 'end';
            this.renderChart({
                labels: this.labels_actual,
                datasets: [
                    {
                        label: 'Entregues',
                        borderColor: '#4ea934',
                        backgroundColor: '#4ea9341a',
                        data: this.data_enviado
                    },
                    {
                        label: 'Abertos',
                        borderColor: '#ff9e44',
                        backgroundColor: '#ff9e441a',
                        data: this.data_aberto
                    },
                    {
                        label: 'Clicados',
                        borderColor: '#3751FF',
                        backgroundColor: '#E9EBFF',
                        data: this.data_clicado
                    },
                    {
                        label: 'Unsubscribeds',
                        borderColor: '#2ed7ee',
                        backgroundColor: '#2ed7ee1a',
                        data: this.data_sairam
                    },
                    {
                        label: 'Spams',
                        borderColor: '#d31408',
                        backgroundColor: '#d314081a',
                        data: this.data_spam
                    },
                    {
                        label: 'Droppeds',
                        borderColor: '#858585',
                        backgroundColor: '#8585851a',
                        data: this.data_dropped
                    },
                    {
                        label: 'Deferreds',
                        borderColor: '#ff8d00',
                        backgroundColor: '#ff8d001a',
                        data: this.data_deferred
                    },
                    {
                        label: 'Bounces',
                        borderColor: '#5d21d2',
                        backgroundColor: '#5d21d21a',
                        data: this.data_bounce
                    },
                ],
            }, {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            maxTicksLimit: 5,
                        },
                    }],
                    xAxes: [{
                        zeroLineWidth: 5,
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            beginAtZero: true,
                            padding: 5,
                            maxTicksLimit: 8,
                        },
                    }]
                },
                legend: {
                    display: true,
                    labels: {
                        fontColor: '#000000',
                        fontSize: 12,
                        usePointStyle: true,
                        boxWidth: 8
                    },
                },
                layout: {
                    margin: {
                        left: 0,
                        right: 150,
                        top: 0,
                        bottom: 0
                    }
                }
            })
            this.$root.$emit('graphmounted');
        },
        montaGraficoSimples(){
            var labelFormatArray = [];
            var labelFormat = this.labels_actual
            for (let i = 0; i < labelFormat.length; i++) {
                const element = moment(labelFormat[i]).format('DD/MM/YYYY');
                labelFormatArray.push(element);
            }
            this.labels_actual = labelFormatArray;
            Chart.defaults.global.defaultFontColor = '#8C8A97';
            Chart.defaults.global.defaultFontFamily = 'Montserrat';
            // Chart.defaults.global.legend.align = 'end';
            this.renderChart({
                labels: this.labels_actual,
                datasets: [
                    {
                        label: 'Entregues',
                        borderColor: '#4ea934',
                        backgroundColor: '#4ea9341a',
                        data: this.data_enviado
                    },
                    {
                        label: 'Abertos',
                        borderColor: '#ff9e44',
                        backgroundColor: '#ff9e441a',
                        data: this.data_aberto
                    },
                    {
                        label: 'Clicados',
                        borderColor: '#3751FF',
                        backgroundColor: '#E9EBFF',
                        data: this.data_clicado
                    },                    
                    {
                        label: 'Unsubscribeds',
                        borderColor: '#2ed7ee',
                        backgroundColor: '#2ed7ee1a',
                        data: this.data_sairam
                    }
                ],
            }, {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            maxTicksLimit: 5,
                        },
                    }],
                    xAxes: [{
                        zeroLineWidth: 5,
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            beginAtZero: true,
                            padding: 5,
                            maxTicksLimit: 8,
                        },
                    }]
                },
                legend: {
                    display: true,
                    labels: {
                        fontColor: '#000000',
                        fontSize: 12,
                        usePointStyle: true,
                        boxWidth: 8
                    },
                },
                layout: {
                    margin: {
                        left: 0,
                        right: 150,
                        top: 0,
                        bottom: 0
                    }
                }
            })
            this.$root.$emit('graphmounted');
        }
    },
    mounted() {
        this.$root.$on('updatefilter', data => {
            this.data_padrao = data;
            if (this.type_graph === 'simples') {
                if (this.simpleInterval) {
                    clearInterval(this.simpleInterval);
                }
                this.buscaDadosSimples(this.data_padrao);
                this.simpleInterval = setInterval(() => {
                    this.buscaDadosSimples(this.data_padrao);
                }, 600000);
            } else {
                if (this.interval) {
                    clearInterval(this.interval);
                }
                this.buscaDados(this.data_padrao);
                this.interval = setInterval(() => {
                    this.buscaDados(this.data_padrao);
                }, 600000);
            }
        });
        this.$root.$on('typegraph', data => {
            if (data === 'simples') {
                if (this.simpleInterval) {
                    clearInterval(this.simpleInterval);
                }
                this.buscaDadosSimples(this.data_padrao);
                this.simpleInterval = setInterval(() => {
                    this.buscaDadosSimples(this.data_padrao);
                }, 600000);
            } else {
                if (this.interval) {
                    clearInterval(this.interval);
                }
                this.buscaDados(this.data_padrao);
                this.interval = setInterval(() => {
                    this.buscaDados(this.data_padrao);
                }, 600000);
            }
        });
    }
}
</script>