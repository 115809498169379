<template>
  <div class="broadcast-html">
    <div class="index">
      <!-- <modal></modal> -->
      <detalhe :broadcast="broadcasts_selecionado"></detalhe>
      <div class="content-broadcast">
        <div class="content-initial">
          <BaseHeader title="Broadcast" :navigation="navigation"></BaseHeader>
          <div style="display: flex; align-items: baseline; gap: 10px">
            <router-link to="/dynamicRoute/apps/broadcast/lista">
              <button
                class="btn-all-broadcast"
                style="border-radius: 10px !important"
              >
                Todos os Broadcasts
              </button>
            </router-link>

            <router-link to="/dynamicRoute/apps/broadcast/wizard">
              <button
                class="new-broadcast"
                id="btn-broadcast"
                :disabled="isButtonDisabled"
              >
                Criar um Broadcast
              </button>
            </router-link>
          </div>
        </div>
        <div class="content-pos espace-text mb-4">
          <p class="text-active mb-0">Disparos mensais</p>
          <!-- <p class="text-detalhe">Visualização detalhada</p> -->

          <router-link :to="{ name: 'EmailsBroadcast' }">
            <img
              v-b-tooltip.hover
              title="Visualizar detalhes"
              class="img_mobile"
              src="@/assets/img/icons/eye.svg"
            />
          </router-link>
        </div>
        <div class="content-leads-actives">
          <p class="text-number-active fakeLeads divFakeLeads">
            {{ emails_mensal | formatNumber }}
          </p>
          <p v-if="this.$store.getters.recursos.nmultiplicationemails" class="text-number-total fakeLeads divFakeLeads">
            / {{ emailLimit }}
          </p>
        </div>
        <div
          class="content-leads-actives2"
          v-bind:class="{ hide: daterangeopen }"
        >

          <div class="dropGrafic" ref="dropdown">
            <BaseButton
              variant="link-info"
              @click="toggleOpt"
              style="zoom: 0.9; width: 100%"
              >Tipo de Gráfico</BaseButton
            >
            <ul v-show="showOptGrafic">
              <li @click="selectGraph('simples')">Simples</li>
              <li @click="selectGraph('completo')">Completo</li>
            </ul>
          </div>
        </div>
        <div class="content-initial mt-5">
          <div class="text-resume">
            <p class="text-resume-initial">Resumo total por</p>
            <div class="flex">
              <p class="text-resume-semana">
                {{ text_filter | moment("DD/MM/YYYY") }}
              </p>
              <p class="text-resume-semana2">
                 - {{ text_filter2 | moment("DD/MM/YYYY") }}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div id="fakeGrafico"></div>
          <grafico id="realGrafico"></grafico>
        </div>
        <div class="flex mt-3">
          <p class="generate-font">
            Gerado em: {{ generate | moment("DD/MM/YYYY hh:mm:ss") }}
          </p>
          <p class="generate-font">
            Será atualizado em:
            {{ generate_next | moment("DD-MM-YYYY hh:mm:ss") }}
          </p>
        </div>
        <div class="recente-cards">
          <p class="title-brodcast mb-3">Recentes</p>
          <div class="content-cards">
            <div v-if="!loading" class="divide-cards">
              <b-skeleton
                v-for="n in 4"
                :key="n"
                width="51px"
                height="13px"
                style="border-radius: 10px; margin-bottom: 30px;"
              ></b-skeleton>
            </div>
            <div class="divide-cards" v-else>
              <div
                class="card-mail"
                v-for="(broadcast, x) in broadcasts"
                :key="x"
              >
                <div class="espace-card-mail">
                  <div class="header-broadcast-card">
                    <p class="title-broadcast" :title="broadcast.title">
                      {{broadcast.title ? broadcast.title : 'Sem título'}}
                    </p>
                    <p class="data-broadcast">
                      {{ broadcast.send_at | moment("DD/MM/YYYY") }}
                    </p>
                  </div>
                  <div class="line-board-broadcast"></div>
                  <div class="container-metrics">
                    <div class="espace-especifics">
                      <div class="flex">
                        <div class="pointer-broadcast point-black"></div>
                        <div>
                          <p class="text-especific">Processados</p>
                        </div>
                      </div>
                      <p
                        class="value-especific"
                        v-if="broadcast.fetched_count === null"
                      >
                        0
                      </p>
                      <p class="value-especific" v-else>
                        {{ broadcast.fetched_count }}
                      </p>
                    </div>
                    <div class="espace-especifics">
                      <div class="flex">
                        <div class="pointer-broadcast point-greenn"></div>
                        <p class="text-especific">Entregues</p>
                      </div>
                      <div>
                        <b-skeleton
                        v-if="broadcast.delivereds_count == undefined"
                        width="51px"
                        height="13px"
                        class="event-skeleton"
                        ></b-skeleton>
                        <p v-else class="value-especific">
                          {{ broadcast.delivereds_count }} /
                          {{ broadcast.percentageDelivereds }}%
                        </p>
                      </div>
                    </div>
                    <div class="espace-especifics">
                      <div class="flex">
                        <div class="pointer-broadcast"></div>
                        <p class="text-especific">Abertos</p>
                      </div>
                      <div>
                        <b-skeleton
                        v-if="broadcast.opens_count == undefined"
                        width="51px"
                        height="13px"
                        class="event-skeleton"
                        ></b-skeleton>
                        <p v-else class="value-especific">
                          {{ broadcast.opens_count }} /
                          {{ broadcast.percentageOpen }}%
                        </p>
                      </div>
                    </div>
                    <div class="espace-especifics">
                      <div class="flex">
                        <div class="pointer-broadcast point-blue2"></div>
                        <p class="text-especific">Clicados</p>
                      </div>
                      <div>
                        <b-skeleton
                        v-if="broadcast.clicks_count == undefined"
                        width="51px"
                        height="13px"
                        class="event-skeleton"
                        ></b-skeleton>
                        <p v-else class="value-especific">
                          {{ broadcast.clicks_count }} /
                          {{ broadcast.percentageClick }}%
                        </p>
                      </div>
                    </div>
                    <div class="espace-especifics">
                      <div class="flex">
                        <div class="pointer-broadcast point-blue"></div>
                        <p class="text-especific">Saíram</p>
                      </div>
                      <div>
                        <b-skeleton
                        v-if="broadcast.unsubscribeds_count == undefined"
                        width="51px"
                        height="13px"
                        class="event-skeleton"
                        ></b-skeleton>
                        <p v-else class="value-especific">
                          {{ broadcast.unsubscribeds_count }} /
                          {{ broadcast.percentageUnsubscribeds }}%
                        </p>
                      </div>
                    </div>
                  </div>
                  <p class="email-card">{{ broadcast.from_address }}</p>
                  <div class="flex space-between email-info">
                    <BaseButton
                    variant="primary"
                      v-on:click="insereModal(broadcast)"
                      @click="showModalDetalhe"
                    >
                      Detalhes
                    </BaseButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Detalhe from "../broadcast/ModalDetalhe";
import Grafico from "../broadcast/Grafico";
import moment from "moment";
import EmailService from "@/services/resources/EmailService";
const serviceEmail = EmailService.build();
import EmailTotalGraphService from "@/services/resources/EmailTotalGraphService";
const serviceEmailTotal = EmailTotalGraphService.build();
import Cookies from "js-cookie";
import Vue from "vue";

export default {
  components: {
    Detalhe,
    Grafico,
  },
  name: "index",
  data() {
    return {
      navigation: [
        { link: "Aplicativos", to: "/dynamicRoute/apps" },
        { link: "Broadcast", to: this.$route.fullPath },
      ],
      showOptGrafic: false,
      text_filter: moment().startOf("hour").subtract(169, "hour"),
      text_filter2: moment().startOf("hour").subtract(1, "hour"),
      generate: "",
      generate_next: "",
      emails_mensal: "0000",
      isButtonDisabled: false,
      startDate: moment()
        .startOf("hour")
        .subtract(730, "hour")
        .format("YYYY-MM-DD"),
      endDate: moment()
        .startOf("hour")
        .subtract(1, "hour")
        .format("YYYY-MM-DD"),
      broadcasts: [],
      broadcasts_selecionado: {},
      loading: false,
      type_graph: "",
      daterangeopen: false,
    };
  },

  filters: {
    formatNumber(number) {
      if (typeof number !== "undefined" && number) {
        return number.toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
          useGrouping: true,
        });
      }
      return "0";
    },
  },

  methods: {
    showModalDetalhe(){
      this.$bvModal.show('bv-modal-detalhe')
    },
    toggleOpt() {
      this.showOptGrafic = !this.showOptGrafic;
      if (this.showOptGrafic) {
        document.addEventListener("click", this.closeOptions);
      } else {
        document.removeEventListener("click", this.closeOptions);
      }
    },
    closeOptions(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.showOptGrafic = false;
      }
    },
    selectGraph(valor) {
      if (valor === undefined) {
        Cookies.set("typegraph", this.type_graph, { expires: 365 });
        this.$root.$emit("typegraph", this.type_graph);
      } else {
        this.type_graph = valor;
        Cookies.set("typegraph", this.type_graph, { expires: 365 });
        this.$root.$emit("typegraph", this.type_graph);
      }
    },
    getCookieGraph() {
      let cookie = Cookies.get("typegraph");
      // console.log(cookie);
      if (cookie === "simples") {
        this.type_graph = "simples";
      } else if (cookie === "completo") {
        this.type_graph = "completo";
      } else if (cookie === undefined || cookie === undefined) {
        this.type_graph = "simples";
      } else {
        this.type_graph = "simples";
      }
      this.selectGraph(cookie);
    },
    async insereModal(broadcast) {
      if(broadcast.percentageDelivereds == undefined){
        return;
      }
      await serviceEmail
        .read(broadcast.id)
        .then((resp) => {
          let moldedEvents = this.parseEvents({...broadcast, ...resp});

          this.broadcasts_selecionado = moldedEvents;
          this.$bvModal.show("bv-modal-detalhe");
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getList() {
      let emptyEvents = {
        fetched_count: 0,
        filtereds_count: 0,
        delivereds_count: 0,
        unsubscribeds_count: 0,
        bounceds_count: 0,
        deferreds_count: 0,
        droppeds_count: 0,
        spams_count: 0,
        opens_count: 0,
        clicks_count: 0,
        percentageOpen: 0,
        percentageClick: 0,
        percentageUnsubscribeds: 0,
        percentageDelivereds: 0,
        percentageSpam: 0,
        percentageDroppped: 0,
        percentageBounce: 0
      }
      serviceEmail
        .read(`?status=ativos&per_page=2`)
        .then((resp) => {
          this.broadcasts = resp.data?.slice(0, 4);

          for (let i = 0; i < this.broadcasts.length; i++) {
            const broadcast = this.broadcasts[i];
            if(broadcast.status === 4){
              this.getEmailEvents(broadcast, i)
            }else{
              Vue.set(this.broadcasts, i, {...this.broadcasts[i], ...emptyEvents});
            }
          }

          let dados = this.broadcasts;
          this.broadcasts = dados
          this.loading = true;
        })
        .catch((err) => {
          this.loading = true;
        });
    },
    getEmailMensal() {
      serviceEmailTotal
        .read("?from_date=" + this.startDate + "&to_date=" + this.endDate)
        .then((resp) => {
          let emails = resp.data.email_delivereds.total_count;
          if (emails !== null) {
            this.emails_mensal = emails;
          } else {
            this.emails_mensal = 0;
          }
          this.removeLoading()

          if (emails && emails >= this.emailLimit) {
            this.$grToast.toast(
              "Você atingiu o limite máximo envios de e-mails desse mês!",
              {
                title: "Broadcast",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            this.isButtonDisabled = true;
            document.getElementById("btn-broadcast").style =
              "transition: 1s;background-color: rgba(0, 0, 0, 0.3)";
          }

        })
        .catch((err) => {
          // console.log(err);
        });
    },
    removeLoading() {
      let div = document.querySelectorAll(".fakeLeads");
      for (let i = 0; i < div.length; i++) {
        div[i].classList.remove("divFakeLeads");
      }
    },
    getEmailEvents(broadcast, index = 0){
      serviceEmail
        .read({id: `${broadcast.id}/events-count?appends=delivereds_count,opens_count,clicks_count,unsubscribeds_count`})
        .then((resp) => {
          let moldedEvents = this.parseEvents({...broadcast, ...resp});
          Vue.set(this.broadcasts, index, moldedEvents);
        }) 
    },
    parseEvents(broadcast){
      let enviado = broadcast.fetched_count;
      if (broadcast.selected_count !== 1 && broadcast.fetched_count === 1) {
        enviado = broadcast.selected_count;
      }

      if (enviado === 0 || enviado === null) {
        broadcast.percentageOpen = 0;
        broadcast.percentageClick = 0;
        broadcast.percentageSpam = 0;
        broadcast.percentageDroppped = 0;
        broadcast.percentageBounce = 0;
        broadcast.percentageUnsubscribeds = 0;
        broadcast.percentageDeferreds = 0;
        broadcast.percentageDelivereds = 0;
      } else {
        let aberto = broadcast.opens_count;
        let clicks = broadcast.clicks_count;
        let spam = broadcast.spams_count;
        let dropped = broadcast.droppeds_count;
        let bounce = broadcast.bounceds_count;
        let unsubscribeds = broadcast.unsubscribeds_count;
        let deferreds = broadcast.deferreds_count;
        let delivereds = broadcast.delivereds_count;

        let porcentagemAberto = (aberto / enviado) * 100;
        let porcentagemClicado = (clicks / enviado) * 100;
        let porcentagemSpam = (spam / enviado) * 100;
        let porcentagemDropped = (dropped / enviado) * 100;
        let porcentagemBounce = (bounce / enviado) * 100;
        let porcentagemUnsubscribeds = (unsubscribeds / enviado) * 100;
        let porcentagemDeferreds = (deferreds / enviado) * 100;
        let porcentagemDelivereds = (delivereds / enviado) * 100;

        broadcast.percentageOpen = parseInt(porcentagemAberto, 10);
        broadcast.percentageClick = parseInt(porcentagemClicado, 10);
        broadcast.percentageSpam = parseInt(porcentagemSpam, 10);
        broadcast.percentageDroppped = parseInt(porcentagemDropped, 10);
        broadcast.percentageBounce = parseInt(porcentagemBounce, 10);
        broadcast.percentageUnsubscribeds = parseInt(
          porcentagemUnsubscribeds,
          10
        );
        broadcast.percentageDeferreds = parseInt(porcentagemDeferreds, 10);
        broadcast.percentageDelivereds = parseInt(porcentagemDelivereds, 10);
      }

      return broadcast;
    }
  },
  computed:{
    emailLimit(){
      const additional = this.$store.getters.recursos.nenvios ? this.$store.getters.recursos.nenvios : 0;
      return (this.$store.getters.recursos.nleads * this.$store.getters.recursos.nmultiplicationemails) + additional;
    }
  },
  mounted() {
    this.getCookieGraph();
    this.$root.$on("updatefilter", (data) => {
      // console.log(data);
      this.text_filter = data[0];
      this.text_filter2 = data[1];
    });
    this.$root.$on("updaterecente", (data) => {
      this.getList();
    });
    this.getList();
    this.getEmailMensal();
    this.$root.$on("getdataupdate", (data) => {
      this.generate = moment(data.generated_at).format("DD/MM/YYYY HH:mm:ss")
      this.generate_next = moment(data.generated_at)
        .add(5, "minutes")
        .format("DD/MM/YYYY HH:mm:ss");
    });
    this.$root.$on("graphmounted", (data) => {
      document.getElementById("fakeGrafico").classList.add("hide");
      document.getElementById("realGrafico").classList.remove("hide");
    });
    this.$root.$on("opendaterange", (data) => {
      this.daterangeopen = data;
    });
  },
};
</script>
<style lang="scss">
.dropGrafic {
  margin-top: -40px;
  ul {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3px;
    padding: 8px 0;
    border: 1px solid #f7f7f7;
    border-top: none;
    border-radius: 10px;
    background: #fff;
  }

  li {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 0;
    width: 100%;
    text-align: center;
    transition: all 0.3s;
    color: #141414;
    cursor: pointer;

    &:hover {
      background-color: #f7f7f7;
    }
    &:active {
      transform: scale(0.95);
    }
  }
}

.top {
  ul {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    a {
      transition: all 0.3s;
      font-size: 14px;
      color: #81858e;
      font-weight: 500;
      text-decoration: none;
      &:hover {
        color: #57585c !important;
      }
    }

    li {
      display: flex;
      gap: 10px;

      a {
        transition: all 0.3s;
        font-size: 14px;
        color: #81858e;
        font-weight: 500;
        text-decoration: none;
        &:hover {
          color: #57585c !important;
        }
      }
    }
  }
}
.broadcast-html {
  .content-leads-actives2 {
    button.btn.dropdown-toggle.btn-secondary {
      background: #ffffff00;
      border: none;
      color: #000;
      height: 20px;
      position: relative;
      left: -22px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }

    .dropdown-item {
      display: block;
      width: 100%;
      padding: 0.25rem 1.5rem;
      clear: both;
      font-weight: 400;
      color: #212529;
      text-align: inherit;
      white-space: nowrap;
      background-color: #0000;
      border: 0;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
    }

    .dropdown-item:hover {
      background: var(--greenn2);
      color: var(--greenn);
    }

    ul.dropdown-menu.show {
      border: none;
    }

    .btn-secondary:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle {
      color: #000;
      background-color: #fff;
      border-color: #fff;
    }
  }
}
</style>
<style lang="scss" scoped>
.header-broadcast-card {
  display: grid;
  grid-template-columns: calc(100% - 80px) 70px;
  gap: 10px;
}
.broadcast-html {
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

  #fakeGrafico {
    width: calc(65vw - 200px);
    height: 265px;
    border-radius: 6px;
    animation: preload 0.8s forwards linear infinite alternate;
    background: #ededf0;
  }

  .divFakeLeads {
    color: #ededf000 !important;
    border-radius: 6px;
    animation: preload 0.8s forwards linear infinite alternate;
    background: #ededf0;
  }

  @keyframes preload {
    from {
      background: #ededf0;
    }

    to {
      background: rgb(192, 192, 192);
    }
  }

  .index {
    width: 84%;
    display: flex;
    // margin-left: 98px;
    // background: #fdfcff;
  }

  .hide {
    display: none !important;
  }

  .point-black {
    background: var(--gray01) !important;
  }

  .point-greenn {
    background: var(--greenn) !important;
  }

  .point-blue {
    background: #2ed7ee !important;
  }

  .point-blue2 {
    background: #3751ff !important;
  }

  .svg-porcentagem {
    margin-left: 10px;
  }

  .btn-all-broadcast {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: var(--gray01);
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: white;
    width: 219px;
    height: 50px;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.05);
    }
  }

  .porcentagem {
    display: flex;
    margin-top: 16px;
  }

  .porcentagem-card {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #000000;
  }

  .container-metrics {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 35px;
  }

  .line-board-broadcast {
    height: 0.5px;
    background: #ededf0;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .space-between {
    justify-content: space-between;
  }

  .espace-especifics {
    margin-right: 15% !important;
  }

  .espace-card-mail {
    margin: 35px;
    width: 100%;
  }

  .value-especific {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #000000;
    margin-left: 19px !important;
    margin-bottom: 30px;
  }

  .text-especific {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #818181;
    margin-bottom: 4px;
    margin-left: 12px !important;
  }

  .pointer-broadcast {
    width: 5px;
    height: 5px;
    background: var(--orange);
    border-radius: 50%;
    margin-top: 7px;
  }

  .data-broadcast {
    margin-bottom: 0px;
    width: 80px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: var(--gray05);
    margin-bottom: 42px;
  }

  .title-broadcast {
    margin-bottom: 0px;
    width: 100%;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 16px;
    color: var(--gray01);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 
    max-height: 46px;
  }

  .email-card {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: var(--gray05);
    margin: 25px 0 !important;
  }

  .generate-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: var(--gray05);
    margin-top: 15px;
    margin-right: 25px;
    margin-left: 15px;
  }

  .card-mail {
    width: 49%;
    height: 450px;
    position: relative;
    background: #fff;
    border: 1px solid #ededf0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 4px 4px rgba(237, 237, 240, 0.25);
    box-shadow: 0px 4px 4px rgba(237, 237, 240, 0.25);
    border-radius: 3px;
    display: flex;
    margin-bottom: 30px;
  }

  .email-info {
    position: absolute;
    bottom: 38px;
  }

  .divide-cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    margin-right: 15%;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  .recente-cards {
    margin-top: 60px;
  }

  .text-topic {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #000000;
    margin-left: 50px;
  }

  .flex {
    display: flex;
  }

  .text-resume-semana {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--gray01);
  }

  .text-resume-semana2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--gray01);
    margin-left: 4px;
  }

  .text-resume-initial {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--gray05);
    margin-right: 5px;
  }

  .text-resume {
    display: flex;
  }

  .topic {
    width: 5px;
    height: 5px;
    margin-top: 8px;
    margin-right: 10px;
    border-radius: 50%;
  }

  .topic-blue {
    background: #3751ff;
    box-shadow: 0px 0px 10px rgba(55, 81, 255, 0.25);
  }

  .topic-green {
    background: var(--greenn);
    box-shadow: 0px 0px 10px rgba(0, 228, 160, 0.25);
  }

  .content-leads-actives2 {
    width: 22%;
    display: flex;
    margin-top: -59px;
    float: right;
  }

  .content-leads-actives {
    width: 100%;
    display: flex;
    margin-top: -10px;
  }

  .text-number-active {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: var(--greenn);
  }

  .text-number-total {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--gray05);
    line-height: 57px;
  }

  .title-brodcast {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: var(--gray01);
  }

  .content-broadcast {
    width: 100%;
  }

  .content-initial {
    display: flex;
    width: 99%;
    justify-content: space-between;
    align-items: baseline;
  }

  .text-active {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--gray05);
  }

  .espace-text {
    margin-top: 50px;
  }

  .content-pos {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  button.new-broadcast {
    width: 219px;
    height: 50px;
    border: none;
    background: var(--greenn);
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.2px;
    color: #fff;
    margin-top: -13px;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.05);
    }
  }

  .event-skeleton{
    border-radius: 10px;
    margin-left: 15px;
    margin-bottom: 30px;
  }

  @media (min-width: 1000px) {
    .index {
      width: 70%;
    }
  }

  @media (max-width: 1000px) {
    .index {
      width: 70%;
    }
  }
}
</style>
