<template>
<div class="broadcast-html">
<div>
    <b-container fluid>
    <feed />
    <index />
    </b-container>
</div>

</div>
</template>

<script>
import BaseView from '@/template/BaseView.vue';
import Index from '@/components/broadcast/Menu.vue';
import Feed from '@/components/broadcast/Feed.vue';

export default {
  components: {
    Index,
    Feed,
    BaseView,
  },
  created() {
    if (this.$store.getters.user.user.level == 'blogger') {this.$router.push("/dynamicRoute/AccessDenied")};
  },
}
</script>

<style lang="scss">
.broadcast-html {


@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

#example .container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.btn-next.btn-div {
    padding: 15px;
    padding-left: 24px;
    cursor: pointer;
}

.date-feed .daterangepicker.dropdown-menu.ltr.show-calendar.show-ranges.show-weeknumbers.opensright.linked {
    position: absolute;
    left: -471px;
}

.date-feed .form-control.reportrange-text {
    padding-left: 31px;
    padding-top: 5px;
    width: 111%;
}

.date-feed .svg-data-feed {
    margin-top: 19px !important;
}

button.applyBtn.btn.btn-sm.btn-success {
    background: var(--greenn);
    width: 77px;
    border-color: var(--greenn);
    font-family: 'Montserrat';
}

.animation-opacity{
  animation: animationFramesOpacity linear 0.3s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  -webkit-animation: animationFramesOpacity linear 0.3s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: animationFramesOpacity linear 0.3s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -o-animation: animationFramesOpacity linear 0.3s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -ms-animation: animationFramesOpacity linear 0.3s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
}

.tamanho-selectx select.select-filter-options.custom-select {
    width: 411px;
}

@keyframes animationFramesOpacity{
  0% {
    opacity:0.17;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes animationFramesOpacity{
  0% {
    opacity:0.17;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes animationFramesOpacity {
  0% {
    opacity:0.17;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes animationFramesOpacity {
  0% {
    opacity:0.17;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes animationFramesOpacity {
  0% {
    opacity:0.17;
  }
  100% {
    opacity:1;
  }
}

// .table thead th {
//     vertical-align: bottom;
//     border-bottom: 2px solid #dee2e6;
//     border: none;
//     background: #f5f2fc80;
//     border-radius: 10px;
//     font-family: Montserrat;
//     font-style: normal;
//     font-weight: 600;
//     font-size: 14px;
//     line-height: 27px;
//     letter-spacing: 0.2px;
//     color: var(--greenn);
//     width: 1%;
// }

div#modal-remetente {
    position: fixed;
    width: 370px;
    height: 400px;
    left: 74%;
    top: 9%;
}

#modal-remetente .modal-content {
    width: 315px;
    height: 90% !important;
}

.date-feed .form-control.reportrange-text {
    padding-top: 13px !important;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    color: #000;
    opacity: inherit !important;
    position: absolute;
    z-index: 999999999999999999999;
    right: 20px;
    top: 10px;
}

.daterangepicker.openscenter:after, .daterangepicker.openscenter:before {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
    display: contents;
}

.daterangepicker.dropdown-menu.ltr.show-calendar.show-weeknumbers.single.openscenter.linked {
    position: absolute;
    top: -148px;
}

div#bv-modal-example {
    padding: 0px !important;
}

#bv-modal-example .modal-body {
    padding-top: 0px;
    margin-top: -10px;
}

.navbar {
    background: white;
    border-bottom: 1px solid lightgray;
}

.navbar-brand {
    font-size: 14px;
    color: #888;
}

.p-0.container-fluid {
    display: flex;
}

canvas#line-chart {
    width: 98% !important;
    height: 265px !important;
}
.modal-open .modal {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.b-form-btn-label-control.dropdown.b-form-datepicker.mb-2.form-control.d-flex.h-auto.align-items-stretch {
    background: var(--greenn2);
    border: 1px solid var(--greenn);
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: var(--gray01);
    height: 50px !important;
}

.b-form-btn-label-control.form-control > label {
    padding-top: 15px;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    color: #000;
    opacity: inherit !important;
}

button.wizard-btn {
    width: 158px;
    height: 50px;
    background: var(--greenn);
    border-radius: 3px;
}

.vue-form-wizard .wizard-nav-pills {
    padding: 12px;
}

span.stepTitle {
    display: none;
}

span.stepTitle.active {
    display: block !important;
}

span.stepTitle.active {
    display: block !important;
    position: relative;
    // left: 62%;
    font-family: Montserrat;
    // bottom: 21px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.2px;
    color: var(--gray01) !important;
}

.modal-header {
    border: none !important;
}

#bv-modal-detalhe .modal-dialog.modal-md {
    // width: 80% !important;
    max-width: inherit !important;
}

.feed .vue-daterange-picker .dropdown-menu {
    padding: 0;
}

.feed .daterangepicker.opensleft {
    right: 10px;
    left: auto;
}

.feed .daterangepicker.show-calendar {
    display: block;
    top: auto;
}

.feed .daterangepicker {
    position: absolute;
    color: inherit;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 278px;
    max-width: none;
    padding: 0;
    margin-top: 7px;
    top: 100px;
    left: 20px;
    z-index: 3001;
    display: none;
    font-size: 15px;
    line-height: 1em;
}

.feed .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #00000026;
    border-radius: 0.25rem;
}

.feed  .daterangepicker {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
}

#bv-modal-detalhe .modal-content {
    height: 98% !important;
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
}

#bv-modal-detalhe .modal-dialog.modal-md {
    height: 100% !important;
    max-height: 650px;
}

#bv-modal-detalhe .modal-dialog.modal-md {
    position: relative !important;
    top: 50% !important;
    transform: translateY(-54%) !important;
}

#bv-modal-detalhe .modal-dialog.modal-md {
    position: relative;
    top: 45%;
    transform: translateY(-50%);
}

div#modal-preview {
    width: 100%;
    height: 100%;
}

.form-control.reportrange-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
    height: 50px;
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 16px;
}

.feed .form-control.reportrange-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
    height: 50px;
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 16px;
    background-color: white;
    width: 220px;
    max-width: 220px;
    min-width: 220px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.feed .form-control.reportrange-text:hover {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
}

.feed .form-control.reportrange-text:focus {
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
}

#modal-preview .modal-content{
    height: 89% !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.feed .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: var(--greenn);
    border-color: #0000;
    color: #fff;
}

body{
    background-color: white !important;
}
}
</style>